<template>
    <div class="mb-3">
        <form-element
            :required="model.required"
            labelClass="body-2"
            :label="model.label"
        >
            <v-radio-group
                v-model="model.value"
                row
                @change="inputUpdate"
                :rules="
                    model.required
                        ? [
                              (v) =>
                                  !!v ||
                                  $t(
                                      'applications.disclosure-response-required',
                                      { label: model.label }
                                  ),
                          ]
                        : []
                "
            >
                <v-radio
                    :label="$t('applications.yes')"
                    value="Yes"
                    color="primary"
                ></v-radio>
                <v-radio
                    :label="$t('applications.no')"
                    value="No"
                    color="primary"
                ></v-radio>
            </v-radio-group>
        </form-element>
        <v-textarea
            v-if="model.value === 'Yes'"
            rows="3"
            auto-grow
            color="primary"
            prepend-inner-icon="comment"
            :label="$t('applications.disclosure-text-label')"
            :rules="
                model.value === 'Yes'
                    ? [
                          (v) =>
                              !!v ||
                              $t('applications.disclosure-text-label-required'),
                          (v) =>
                              v.length > 10 ||
                              $t('applications.disclosure-text-label-required'),
                      ]
                    : []
            "
            :required="model.value === 'Yes'"
            v-model="model.text"
            @update="inputUpdate"
        ></v-textarea>
    </div>
</template>
<script>
export default {
    name: "applicant-disclosure",
    components: {},
    props: {
        disclosureData: {
            type: Object,
            required: true,
            validator: (value) => {
                const params = Object.keys(value);
                return [
                    "id",
                    "text",
                    "value",
                    "name",
                    "label",
                    "required",
                    "textLabel",
                ].every((elem) => params.includes(elem));
            },
        },
    },
    data() {
        return {
            model: this.disclosureData,
        };
    },
    mounted() {},
    created() {},
    updated() {},
    methods: {
        inputUpdate() {
            this.$emit("update:disclosure", this.model.id, this.model);
        },
    },
};
</script>

<style scoped>
</style>