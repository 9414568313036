var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-3" },
    [
      _c(
        "form-element",
        {
          attrs: {
            required: _vm.model.required,
            labelClass: "body-2",
            label: _vm.model.label
          }
        },
        [
          _c(
            "v-radio-group",
            {
              attrs: {
                row: "",
                rules: _vm.model.required
                  ? [
                      function(v) {
                        return (
                          !!v ||
                          _vm.$t("applications.disclosure-response-required", {
                            label: _vm.model.label
                          })
                        )
                      }
                    ]
                  : []
              },
              on: { change: _vm.inputUpdate },
              model: {
                value: _vm.model.value,
                callback: function($$v) {
                  _vm.$set(_vm.model, "value", $$v)
                },
                expression: "model.value"
              }
            },
            [
              _c("v-radio", {
                attrs: {
                  label: _vm.$t("applications.yes"),
                  value: "Yes",
                  color: "primary"
                }
              }),
              _c("v-radio", {
                attrs: {
                  label: _vm.$t("applications.no"),
                  value: "No",
                  color: "primary"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.model.value === "Yes"
        ? _c("v-textarea", {
            attrs: {
              rows: "3",
              "auto-grow": "",
              color: "primary",
              "prepend-inner-icon": "comment",
              label: _vm.$t("applications.disclosure-text-label"),
              rules:
                _vm.model.value === "Yes"
                  ? [
                      function(v) {
                        return (
                          !!v ||
                          _vm.$t("applications.disclosure-text-label-required")
                        )
                      },
                      function(v) {
                        return (
                          v.length > 10 ||
                          _vm.$t("applications.disclosure-text-label-required")
                        )
                      }
                    ]
                  : [],
              required: _vm.model.value === "Yes"
            },
            on: { update: _vm.inputUpdate },
            model: {
              value: _vm.model.text,
              callback: function($$v) {
                _vm.$set(_vm.model, "text", $$v)
              },
              expression: "model.text"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }